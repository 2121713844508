<template>
<Transition appear>
	<div class="tw-card">
		<h1 class="tw-heading">Not Eligible</h1>
		<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor quas accusamus deserunt animi quam deleniti magnam accusantium asperiores odit id dolorum praesentium ipsum rerum, atque aut ex voluptates repudiandae earum!</p>
	</div>
</Transition>
</template>

<script>
export default {
	name: 'NotEligible'
}
</script>