<template>
	<Transition appear>
		<div class="questionset card">
			<h1>Sign</h1>
			<div class="content">
				
				<button type="button">Sign Document</button>
			</div>
		</div>
	</Transition>
</template>

<script>
    
	export default {
		name: 'signNow'
        
	}
</script>